.btn {
  text-decoration: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 8px;
}
.btn--PO {
  padding: 8px 9px 10px 8px;
  border-radius: 6px;
  color: #FFFFFF;
  background-color: #303030;
}
.btn--F {
  padding: 8px;
  border-radius: 2px;
  color: #FFFFFF;
  background-color: #698A70;
}
.btn--TW {
  padding: 12px;
  border-radius: 32px;
  color: #FFFFFF;
  background-color: #FF6C67;
}
.btn--QP {
  padding: 8px;
  color: #303030;
  background-color: #FFFFFF;
}
.btn--QP::after {
  content: none;
  background-color: #303030;
}
.btn--GC {
  padding: 12px;
  border: 1px #303030 solid;
}
.btn--AWS {
  padding: 8px;
  border-radius: 2px;
  color: #303030;
  background-color: #FFFFFF;
}
.btn--dark {
  padding: 8px 9px 10px 8px;
  margin-right: 8px;
  border-radius: 6px;
  color: #FFFFFF;
  background-color: #303030;
}
.btn--light {
  padding: 8px 9px 10px 8px;
  margin-right: 8px;
  border-radius: 6px;
  color: #303030;
  background-color: #FFFFFF;
}
.btn__floating {
  position: absolute;
  height: 32px;
  width: 32px;
  top: 3%;
  left: 85%;
  text-decoration: none;
  border: 2px solid #303030;
  border-radius: 99px;
}
.btn__vertical:hover {
  cursor: pointer;
}/*# sourceMappingURL=buttons.css.map */