@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");
h1 {
  margin: 0;
  font-size: 6.125rem;
  letter-spacing: -0.05rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.08;
  margin-bottom: 0.2rem;
}
@media (max-width: 599px) {
  h1 {
    font-size: 4.0833rem;
    letter-spacing: -0.12rem;
    font-weight: 700;
    margin-bottom: 2.25rem;
  }
}

h2 {
  margin: 0;
  font-size: 3.75rem;
  letter-spacing: -0.08rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.05;
  margin-bottom: 0.2rem;
}

h3 {
  margin: 0;
  font-size: 3rem;
  letter-spacing: -0.1rem;
  font-family: "Space Mono", monospace;
  font-style: italic;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0.2em;
}

h4 {
  margin: 0;
  font-size: 2.125rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.1;
}

h5 {
  margin: 0;
  font-size: 1.75rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.05;
}

h6 {
  margin: 0;
  font-size: 1.25rem;
  font-family: "Space Mono", monospace;
  font-style: italic;
  font-weight: 700;
  line-height: 1.1;
}
@media (max-width: 599px) {
  h6 {
    font-size: 1.1rem;
  }
}

a {
  margin: 0;
  font-size: 1.25rem;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 600;
  line-height: 1.2;
}

.type__space--ital {
  margin: 0;
  font-size: 3.5rem;
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: italic;
  line-height: 2.2;
}

.type__space--boldItal {
  margin: 0;
  font-size: 3.5rem;
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: italic;
  line-height: 2.2;
}

.type__subtitle {
  margin: 0;
  font-size: 1.2rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.4rem;
}

.type__subtitle-small {
  margin: 0;
  font-size: 0.875rem;
  font-family: "Merriweather", serif;
  font-weight: 400;
  line-height: 1.3;
}

.type__body {
  margin: 0;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.55;
  letter-spacing: -0.02rem;
}
@media (max-width: 599px) {
  .type__body {
    font-size: 1.12rem;
    letter-spacing: -0.01rem;
  }
}

.type__body-small {
  margin: 0;
  font-size: 0.875rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

.type__button {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
}

.type__caption {
  font-size: 0.75rem;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 300;
  line-height: 1.3;
}

.type__overline {
  font-size: 0.625rem;
  letter-spacing: 0.135em;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.35;
}
@media (max-width: 599px) {
  .type__overline {
    font-size: 0.825rem;
  }
}

.type__category {
  font-size: 0.625rem;
  letter-spacing: 0.1em;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.4;
}

.type__link {
  margin: 0;
  text-decoration: underline;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  line-height: 1.55;
  letter-spacing: -0.02rem;
}
@media (max-width: 599px) {
  .type__link {
    font-size: 1.12rem;
    letter-spacing: -0.01rem;
  }
}

.intro {
  max-width: 100ch;
}/*# sourceMappingURL=typography.css.map */