* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}
@media (max-width: 599px) {
  html {
    font-size: 12px;
  }
}

#scene {
  position: absolute;
  width: 100vw;
  height: 100vh;
}
#scene canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;
}

img {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 0px rgba(248, 243, 139, 0);
  transition: all 350ms cubic-bezier(0.19, 1, 0.22, 1);
}
img.tribeWellness:hover {
  box-shadow: 0px 2px 30px -4px rgb(255, 205, 208);
}
img.greatCuts:hover {
  box-shadow: 0px 2px 30px -4px rgb(250, 241, 207);
}

object {
  max-width: 100%;
  width: 100%;
}

.commonSpan {
  margin-bottom: 1em;
  display: inline-block;
  background-color: rgba(64, 224, 208, 0);
}

.mb-m {
  margin-bottom: 1em;
}

.mb-lg {
  margin-bottom: 2em;
}

.mb-xlg {
  margin-bottom: 3em;
}

.noScroll {
  overflow-y: hidden !important;
}

.scroll {
  overflow-y: scroll !important;
}

.none {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.grid {
  display: grid !important;
}

.pointer:hover {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.vertical {
  transform: translate(45%, 100%) rotateZ(90deg);
  margin-left: auto;
}

.icon {
  float: left;
  margin-right: 0.6em;
}
.icon__special {
  height: 1.25em;
  width: 1.25em;
  transform: rotate(90deg);
}
.icon__down {
  height: 2.69em;
  width: 1em;
  transform: translate(200%, -15%) rotateZ(-90deg);
}

.fxcntr {
  margin-bottom: 1em;
  display: flex;
}
.fxcntr--jspcbtwn {
  justify-content: space-between;
}
.fxcntr--jspcevn {
  justify-content: space-evenly;
}
.fxcntr--jfxstart {
  justify-content: flex-start;
}
.fxcntr--jfxend {
  justify-content: flex-end;
}
.fxcntr--row {
  flex-direction: row;
}
.fxcntr--rowReverse {
  flex-direction: row-reverse;
}
.fxcntr--column {
  flex-direction: column;
}
.fxcntr--columnReverse {
  flex-direction: column-reverse;
}
.fxcntr__item--start {
  justify-self: flex-start;
}
.fxcntr__item--end {
  justify-self: flex-end;
}

.surface--dark {
  background-color: #303030;
}
.surface--light {
  background-color: #FFFFFF;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0;
  color: #303030;
  box-sizing: border-box;
}
.container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.container:first-child {
  padding-top: 12vh;
}
@media (max-width: 599px) {
  .container:first-child {
    padding-top: 12vh;
  }
}
@media (min-width: 321px) {
  .container {
    width: 80%;
  }
}
@media (max-width: 319px) {
  .container {
    width: 90%;
  }
}

.columns {
  float: left;
  margin-left: 4%;
  box-sizing: border-box;
}
.columns:first-child {
  margin-left: 0;
}
.columns--one {
  width: 4.67%;
}
.columns--two {
  width: 13%;
}
.columns--three {
  width: 22%;
}
.columns--four {
  width: 30.67%;
}
.columns--five {
  width: 39.33%;
}
.columns--six {
  width: 48%;
}
.columns--seven {
  width: 56.67%;
}
.columns--eight {
  width: 65.33%;
}
.columns--nine {
  width: 74%;
}
.columns--ten {
  width: 82.67%;
}
.columns--eleven {
  width: 91.33%;
}
.columns--twelve {
  width: 100%;
  margin-left: 0;
}

.row {
  margin-bottom: 4.5rem;
}
@media (max-width: 599px) {
  .row {
    margin-top: 3.5rem;
  }
  .row .display:first-child {
    margin-bottom: 3.5rem;
  }
}
.row__header {
  margin-bottom: 1.5rem;
}
.row:first-child {
  margin-top: 4.5rem;
}
@media (max-width: 599px) {
  .row:first-child {
    margin-top: 0;
  }
}
.row::after {
  content: "";
  clear: both;
  display: table;
}

.accent {
  background-color: #EEEEEE;
  padding: 16px 16px 8px 16px;
  border-radius: 2px;
}

.project__caption {
  max-width: 60%;
}

.skills {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.skills .category {
  grid-column: span 1/span 1;
}
.skills .skill {
  grid-column: span 2/span 2;
}

.resume object {
  visibility: visible;
}
.resume img {
  height: 0;
  width: 0;
  visibility: hidden;
}
@media (max-width: 599px) {
  .resume object {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .resume img {
    height: auto;
    width: 100%;
    max-width: 100%;
    visibility: visible;
  }
}/*# sourceMappingURL=common.css.map */